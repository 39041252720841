import { FallbackProps } from "react-error-boundary";

import process from "process";

import { Code, Flex } from "@chakra-ui/react";

import { Text } from "@components/Text";

export const isDev = process.env.NODE_ENV === "development";

const ErrorBoundaryFBContainer = ({ error }: FallbackProps) => {
    return (
        <Flex
            direction="column"
            gap={7}
            h="100vh"
            justify="center"
            align="center"
            px={6}
        >
            <Flex gap={10} direction="column" justify="center" align="center">
                <Text type="heading" fontSize={48}>
                    App Error
                </Text>
                <Text fontSize={18}>
                    Unexpected error occured. Please try reloading this page.
                </Text>
            </Flex>
            {isDev && (
                <Flex
                    direction="column"
                    w={["full", null, null, 800]}
                    gap={4}
                    p={4}
                    bg="gray.100"
                    borderRadius={10}
                >
                    <Text> 💻 Debug info</Text>
                    <Code
                        display="block"
                        whiteSpace="pre"
                        children={error.stack}
                        overflow="auto"
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default ErrorBoundaryFBContainer;
