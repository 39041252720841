import { cssVar, defineStyleConfig } from "@chakra-ui/react";

const $arrowBg = cssVar("popper-arrow-bg");
const Tooltip = defineStyleConfig({
    baseStyle: {
        bg: "gray.800",
        p: 2,
        fontSize: 14,
        borderRadius: 4,
        [$arrowBg.variable]: "colors.gray.800"
    }
});
export default Tooltip;
